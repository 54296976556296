<template>
  <div class="content" v-loading="loading">
    <div class="container-fluid mt--6">
      <div class="col-xl-12">
        <card v-if="!!inspection.inspection_id">
          <!-- Confirmed status -->
          <div v-if="inspection.status === InspectionService.INSPECTION_STATUS_CONFIRMED">
            <div class="row">
              <div class="col-lg-12 mt-3">
                <p>Sorry! This booking confirmation link has expired.</p>
              </div>
            </div>
          </div>
          <!-- Confirmed status -->

          <div v-else>
            <div class="text-center">
              <h1 class="mb-0" id="inspection_request_heading">Inspection Request</h1>
              <br />
              <div class="row">
                <div class="col-lg-12">
                  <h3 id="inspection_details">
                    <span class="font-italic">For </span>
                    <strong>
                      {{
                        (inspection.firstName || "") +
                          " " +
                          (inspection.lastName || "")
                      }}
                    </strong>
                    <span
                      v-if="inspection.eventType.toLowerCase() === 'wedding'"
                    >
                      &
                      <strong>
                        {{
                          (inspection.partnersFirstName || "") +
                            " " +
                            (inspection.partnersLastName || "")
                        }}
                      </strong>
                    </span>
                    <span class="font-italic"> on </span>
                    <strong>{{
                      inspection.inspectionDate | moment("dddd, MMMM Do YYYY")
                    }}</strong>
                  </h3>
                </div>
              </div>
              <div class="row" v-if="formSubmitted === false">
                <div class="col-lg-12">
                  <h6 class="heading-small text-muted mt-4">
                    Please accept a booking time withing the following period:
                  </h6>
                </div>
              </div>
            </div>

            <form
              @submit.prevent="submitRequest"
              v-if="formSubmitted === false"
            >
              <div
                class="availability-slots"
                v-for="(time, index) in inspection.availability"
                :key="index"
              >
                <div class="hrdivider">
                  <span>
                    <small>{{ time.time }}</small>
                  </span>
                  <hr />
                </div>
                <div class="row">
                  <div class="offset-sm-2 col-sm-8">
                    <button-checkbox
                      v-if="time.available"
                      v-model="time.selected"
                      @change="onChange(index, time.time)"
                      class="btn-outline-secondary"
                      button-classes="btn btn-block"
                    >
                      <span v-if="!time.selected">Accept Booking</span>
                      <span v-else>
                        <i class="fa fa-check"></i>
                        You have accepted this booking
                      </span>
                    </button-checkbox>

                    <div v-else class="taken">
                      <span>Booking Taken</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="offset-sm-2 col-sm-8">
                  <div class="alert alert-secondary" role="alert">
                    If you are unable to accommodate any of the above booking
                    slots, but wish to discuss an alternative booking, please
                    call us on
                    <a :href="'tel:' + inspection.venue.venue_specialist_phone_number">
                      {{ inspection.venue.venue_specialist_phone_number }}
                    </a> or email:
                    <a :href="'mailto:' + inspection.venue.venue_specialist_email">
                      {{inspection.venue.venue_specialist_email}}
                    </a>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="offset-sm-2 col-sm-8">
                  <div class="form-group">
                    <label class="form-control-label">Add Message</label>
                    <textarea
                      rows="6"
                      id="reply_text_box"
                      class="form-control"
                      v-model="formData.reply"
                      placeholder="A few words about your event ..."
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <base-button type="primary" native-type="submit" class="my-4" id="send_button">
                  Send
                </base-button>
              </div>
            </form>

            <div v-if="formSubmitted === true">
              <div class="row">
                <div class="col-lg-12 mt-3">
                  <p>Thank you! We've saved your request.</p>
                </div>
              </div>
            </div>

            <el-dialog
              id="dialog_time_slot_taken"
              title="Time Slot Token"
              :visible.sync="dialogVisible"
              :show-close="showDialogclose"
              :close-on-press-escape="showDialogclose"
              :close-on-click-modal="showDialogclose"
              width="30%"
              class="error-div"
            >
              <span v-if="submitErrorType == 'Already Confirmed'">
                Sorry, it seems venue is already confirmed.
              </span>
              <span v-else-if="submitErrorType == 'Inspection cancelled'">
                Sorry, it seems inspection is cancelled.
              </span>
              <span v-else>
                Sorry, it seems another venue has just token this slot.
                <br />
                <span>Please select another time.</span>
              </span>
            </el-dialog>
          </div>
        </card>

        <!-- Error handler -->
        <div v-if="error" class="not-found">
          <h3>Inspections with current ID not found</h3>
        </div>
        <!-- Error handler -->
      </div>
    </div>
  </div>
</template>

<script>
import ButtonCheckbox from "@/components/ButtonCheckbox";
import InspectionService from "@/services/inspection.service";

import Vue from "vue";
import { dialog } from "element-ui";

Vue.use(dialog);

export default {
  components: {
    ButtonCheckbox,
  },
  data() {
    return {
      InspectionService,
      formData: {},
      dialogVisible: false,
      loading: true,
      error: "",
      previousIndex: 0,
      key: this.$route.params.id,
      formSubmitted: false,
      submitErrorType: "",
      showDialogclose: true,
    };
  },
  async created() {
    this.$store
      .dispatch("inspections/getInspectionByKey", this.key)
      .then(() => {
        this.loading = false;
        if(this.inspection.inspection_status === InspectionService.INSPECTION_STATUS_CONFIRMED) {
          this.dialogVisible = true;
          this.submitErrorType = "Already Confirmed";
          this.showDialogclose = false;
        }
        if(this.inspection.inspection_status === InspectionService.INSPECTION_STATUS_CANCELLED) {
          this.dialogVisible = true;
          this.submitErrorType = "Inspection cancelled";
          this.showDialogclose = false;
        }
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
      });
  },
  computed: {
    inspection() {
      return this.$store.getters["inspections/getInspectionsByUniqueId"](
        this.key
      );
    },
  },
  methods: {
    onChange(index, time) {
      this.inspection.availability[this.previousIndex].selected = false;
      this.inspection.availability[index].selected = true;

      this.formData.time = time;
      this.previousIndex = index;
    },
    submitRequest() {
      this.loading = true;
      this.$store
        .dispatch("inspections/confirmInspectionBooking", {
          inspection_unique_id: this.key,
          formData: this.formData,
        })
        .then((response) => {
          this.formSubmitted = true;
          this.loading = false;
        })
        .catch((error) => {
          this.submitErrorType = error.response.data.error.type;
          this.dialogVisible = true;
          this.loading = false;
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.no-border-card .card-footer {
  border-top: 0;
}

.hrdivider {
  position: relative;
  margin: 10px 0;
  width: 100%;
  text-align: center;

  span {
    position: absolute;
    top: -14px;
    background: #fff;
    padding: 0 10px 0 0;
    font-weight: bold;
    left: 0;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.taken span {
  background: #f2f4f6;
  width: 100%;
  padding: 10px;
  display: block;
  text-align: center;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #434343;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn:not(.active) {
  background-color: #ee9282;
  color: #fff;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.active {
  background-color: #0dc972;
  color: #fff;
}

.btn-primary {
  background-color: #ee9282;
  border-color: #ee9282;
  color: #fff;

  &:hover {
    background-color: #eb7e6c;
    border-color: #eb7e6c;
    color: #fff;
  }
  &:active {
    background-color: #e86b55 !important;
    border-color: #e86b55 !important;
    color: #fff;
  }
}
</style>
